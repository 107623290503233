* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html, body {
  background: #333332;
}
#container {
  max-width: 2560px;
  max-height: 1440px;
  height: 100%;
  margin: 0 auto;
}
